import React, { useState } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { Logo } from "../assets/icons"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import { useSwipeable } from "react-swipeable"
import NavigationMenu from "../components/navigation"
import { getPath, getCurrentIndex } from "../utils/navigation"

export default function Layout({ children, classNames }) {
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const isRootPath =
    location.pathname === `/${i18n.language}/` || location.pathname === `/`

  const [closeMenu, setCloseMenu] = useState(false)
  const [menuClicked, setMenuClicked] = useState(false)
  const [contentTransitionClass, setContentTransitionClass] = useState("")

  const pages = ["home", "solutions", "products", "contact", "about"]

  const handleMenuClick = path => {
    if (isRootPath && !menuClicked) {
      setMenuClicked(true)
      setTimeout(() => navigate(path), 850)
    } else {
      const currentIndex = getCurrentIndex(location, pages, i18n.language)
      const targetIndex = pages.indexOf(
        path.replace(`/${i18n.language}/`, "").replace(`/`, "")
      )

      if (targetIndex > currentIndex) {
        setContentTransitionClass("slide-out-left")
      } else {
        setContentTransitionClass("slide-out-right")
      }

      setTimeout(() => {
        navigate(path)
        setContentTransitionClass(
          targetIndex > currentIndex ? "slide-in-right" : "slide-in-left"
        )
      }, 200)
    }
  }

  const handleLogoClick = () => {
    const path = i18n.language === "en" ? `/` : `/${i18n.language}/`
    if (!isRootPath) {
      setMenuClicked(false)
      setCloseMenu(true)
      navigate(path)
    } else {
      navigate(path)
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = getCurrentIndex(location, pages, i18n.language)
      if (currentIndex < pages.length - 1) {
        const nextPage = pages[currentIndex + 1]
        const nextPath = getPath(i18n.language, nextPage)
        setContentTransitionClass("slide-out-left")
        navigate(nextPath)
        setContentTransitionClass("slide-in-right")
      }
    },
    onSwipedRight: () => {
      const currentIndex = getCurrentIndex(location, pages, i18n.language)
      if (currentIndex > 0) {
        const prevPage = pages[currentIndex - 1]
        const prevPath = getPath(i18n.language, prevPage)
        setContentTransitionClass("slide-out-right")
        navigate(prevPath)
        setContentTransitionClass("slide-in-left")
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const headerClass = isRootPath
    ? menuClicked
      ? "animate-logoMoveUp"
      : closeMenu
      ? "animate-logoMoveDown"
      : ""
    : "sticky top-0 bg-opacity-75 z-10 py-2"

  const footerClass = isRootPath
    ? menuClicked
      ? "animate-menuMoveDown"
      : closeMenu
      ? "animate-menuMoveUp"
      : ""
    : "sticky bottom-0 bg-opacity-75 z-10"

  return (
    <div
      className={`flex flex-col min-h-screen ${
        isRootPath ? "items-center justify-center" : ""
      }`}
      style={{ backgroundColor: "#3A3938" }}
    >
      <header
        className={`flex justify-center ${headerClass}`}
        style={{ backgroundColor: "#3A3938" }}
      >
        <Link
          to="/"
          onClick={handleLogoClick}
          style={{ border: "none", background: "none" }}
        >
          <Logo width={"24rem"} />
        </Link>
      </header>

      {!isRootPath && (
        <main
          className={`flex-grow flex items-center justify-center overflow-y-auto px-4 ${
            classNames || ""
          } ${contentTransitionClass}`}
          {...swipeHandlers}
          onAnimationEnd={() => setContentTransitionClass("")}
        >
          {children}
        </main>
      )}

      <footer
        className={`flex justify-center ${footerClass} ${
          !isRootPath ? "py-4" : ""
        }`}
        style={{ backgroundColor: "#3A3938" }}
      >
        <NavigationMenu
          pages={pages}
          i18n={i18n}
          handleMenuClick={handleMenuClick}
          t={t}
        />
      </footer>
    </div>
  )
}
